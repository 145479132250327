/**
 * @todo Do not run on touch devices?
 * @todo Do not run on breakpoints? Customisable? Can we run based on whether container is visible?
 * @returns {{prepare(*): void, readonly maxDiagonalIndex: *, reset(): void, maxWordsPerLine: number, lines: *[], maxWordsLastLine: number}|number}
 */
export default function() {
  return {
    lines : [],
    maxWordsLastLine : 0,
    maxWordsPerLine : 0,
    get maxDiagonalIndex() {
      return this.lines.length * this.maxWordsPerLine;
    },
    reset() {
      this.lines = [];
      this.maxWordsLastLine = 0;
      this.maxWordsPerLine = 0;
    },
    prepare(container) {
      this.reset();

      let currentLine = [];
      let lastTop = null;

      for (let i = 0; i < container.children.length; i++) {
        const word = container.children[i];
        const rect = word.getBoundingClientRect();

        if (lastTop === null || Math.abs(rect.top - lastTop) < 2) { // Added small threshold
          // Same line
          currentLine.push(word);
          this.maxWordsPerLine = Math.max(this.maxWordsPerLine, currentLine.length);
        } else {
          // New line
          this.lines.push(currentLine);
          currentLine = [word];
        }

        // set props to CSS
        word.style.setProperty('--lineNumber', this.lines.length + 1);
        word.style.setProperty('--wordNumber', currentLine.length);

        // set for next loop
        lastTop = rect.top;
      }

      // Don't forget to add the last line
      if (currentLine.length > 0) {
        this.lines.push(currentLine);
        this.maxWordsLastLine = currentLine.length;
        this.maxWordsPerLine = Math.max(this.maxWordsPerLine, currentLine.length);
      }
    }
  };
}
