export function carousel(slideCount) {
  return {
    currentIndex: 0,
    moveInterval: null,
    started: false,
    prevWidth: null,
    slidesVisible: [],
    atStart: false,
    atEnd: false,
    init() {
      this.prevWidth = window.screen.width;
    },
    start() {
      this.started = true;
    },
    next() {
      this.to((current, offset) => current + offset);
    },
    prev() {
      this.to((current, offset) => current - offset);
    },
    goTo(idx) {
      this.to((current, offset) => offset * (idx));
    },
    to(strategy) {
      /*
      let slider = this.$refs.slider;
      let slides = this.$refs.slides;
      let current = slider.scrollLeft
      let offset = slides.firstElementChild.getBoundingClientRect().width
      let behavior = 'smooth';
      let left = window.screen.width >= 1024 ? 0 : strategy(current, offset);
      slider.scrollTo({ left, behavior });

       */

      let slider = this.$refs.slider;
      let slides = this.$refs.slides;
      let current = slides.scrollLeft;
      console.log(current);
      let offset = slides.firstElementChild.getBoundingClientRect().width;
      slides.scrollTo({ left: strategy(current, offset), behavior: 'smooth' })
    },
    focusableWhenVisible: {
      'x-intersect:enter'() {
        this.$el.removeAttribute('tabindex')
      },
      'x-intersect:leave'() {
        this.$el.setAttribute('tabindex', '-1')
      },
    },
    intersections: {
      'x-intersect:enter.threshold.50'() {
        let slideEls = this.$el.parentElement.getElementsByClassName('slide');
        if(slideEls[0] === this.$el) {
          this.atStart = true;
        } else if (slideEls[slideEls.length-1] === this.$el) {
          this.atEnd = true;
        }
        let slideIdx = Array.from(slideEls).indexOf(this.$el);
        this.slidesVisible.push(slideIdx);
      },
      'x-intersect:leave.threshold.50'() {
        let slideEls = this.$el.parentElement.getElementsByClassName('slide');
        // If this is the first slide.
        if (slideEls[0] === this.$el) {
          this.atStart = false;
          // If this is the last slide.
        } else if (slideEls[slideEls.length-1] === this.$el) {
          this.atEnd = false;
        }
        let slideIdx = Array.from(slideEls).indexOf(this.$el);
        this.slidesVisible = this.slidesVisible.filter(s => s !== slideIdx);
      },
    }
  };
}
