/**
 * Array of CSS selectors to follow & preload (see below)
 * @type {string[]}
 */
const yesSelectors = [];

/**
 * Array of CSS selectors to NOT follow & preload (see below)
 * @type {string[]}
 */
const noSelectors = [
  'a[href]',
];

/**
 * Tweak default navigation behavior
 * https://unpoly.com/navigation
 * @type {boolean}
 */
up.fragment.config.navigateOptions.focus = false;

/**
 * Automatically follow (most) internal links
 * https://unpoly.com/up.link.config#config.followSelectors
 * https://unpoly.com/up.link.config#config.noFollowSelectors
 */
up.link.config.followSelectors.push(...yesSelectors);
up.link.config.noFollowSelectors.push(...noSelectors);

/**
 * Automatically preload (most) internal links
 * https://unpoly.com/up.link.config#config.preloadSelectors
 * https://unpoly.com/up.link.config#config.noPreloadSelectors
 */
up.link.config.preloadSelectors.push(...yesSelectors);
up.link.config.noPreloadSelectors.push(...noSelectors);
