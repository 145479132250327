import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

up.on("up:fragment:inserted", function (event, fragment) {
  console.log("gsap up:fragment:inserted");

  if (window.matchMedia('min-width: 768px').matches) {
    return;
  }

  // without a timeout, ScrollTrigger triggers every reveal immediately
  if(window.innerWidth >= 1024) {
    setTimeout(() => {

      // Reveals
      (function () {
        const elems = fragment.querySelectorAll("[data-reveal]");

        if (!elems) return;

        up.util.each(elems, function (elem) {
          gsap.set(elem, {autoAlpha: 0});

          ScrollTrigger.create({
            trigger: elem,
            start: "top 90%",
            once: true,
            onEnter: function (self) {
              var distance = elem.getAttribute("data-reveal-distance") || 100,
                delay = elem.getAttribute("data-reveal-delay") || 0.3,
                duration = elem.getAttribute("data-reveal-duration") || 1.25,
                x = 0,
                y = distance;

              // 'up' is default; other options are right or left
              switch (elem.getAttribute("data-reveal-direction")) {
                case "right":
                  x = distance * -1;
                  y = 0;
                  break;

                case "left":
                  x = distance;
                  y = 0;
              }

              gsap.fromTo(
                elem,
                { x: x, y: y, autoAlpha: 0 },
                {
                  delay: delay,
                  duration: duration,
                  x: 0,
                  y: 0,
                  autoAlpha: 1,
                  ease: "expo",
                  overwrite: "auto",
                }
              );
            },
          });
        });
      })();

      // Pinned Slides
      (function () {
        const elems = fragment.querySelectorAll("[data-pinned-slides]");

        if (!elems) return;

        up.util.each(elems, function (elem) {
          ScrollTrigger.create({
            trigger: elem,
            start: "top top",
            end: "bottom bottom",
            pin: "#pin",
          });

          let currentSlide = 1;

          gsap.set(elem.getElementById("img" + currentSlide), {
            opacity: 1,
            ease: "easeInExpo",
            duration: 1.25,
          });

          function switchSlides(nextSlide) {
            if (currentSlide != nextSlide) {
              gsap.to(elem.getElementById("img" + currentSlide), {
                opacity: 0,
                ease: "easeInExpo",
                duration: 1.25,
              });
              gsap.to(elem.getElementById("img" + nextSlide), {
                opacity: 1,
                ease: "easeInExpo",
                duration: 1.25,
              });
              currentSlide = nextSlide;
            }
          }

          let slides = elem.querySelectorAll("[data-slide]");
          slides.forEach((slide) => {
            ScrollTrigger.create({
              trigger: slide,
              start: "top 70%",
              end: "bottom 20%",
              onEnter: () => switchSlides(slide.dataset.slide),
              onEnterBack: () => switchSlides(slide.dataset.slide)
            });
          });
        });
      })();

      // Video Playback
      (function () {
        const elems = fragment.querySelectorAll("[data-gsap-video']");

        if (!elems) return;

        up.util.each(elems, function (elem) {
          let videoEle = elem.querySelector("video");

          ScrollTrigger.create({
            trigger: videoEle,
            start: "top 60%",
            onEnter: () => videoEle.play(),
          });
        });
      })();
  
    }, 0);
  }
});
