import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import intersect from '@alpinejs/intersect';
import revealWords from "./revealWords.js";
import saved from "./saved.js";
import savedItems from "./savedItems.js";
import { carousel } from "./carousel.js";

/* plugins */
Alpine.plugin(persist);
Alpine.plugin(intersect);

/* components */
Alpine.data("revealWords", revealWords);
Alpine.data("saved", saved);
Alpine.data("savedItems", savedItems);
Alpine.data("carousel", carousel);

/* saved item functionality */
Alpine.store('application', {
  items : Alpine.$persist([]).as('application_items_2024'),
});

/* start your engines */
window.Alpine = Alpine;
Alpine.start();
